import { MutableRefObject, useEffect } from 'react';
function SessionizeFrame(sessionizeContainerRef: MutableRefObject<undefined>) {
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Append the script to the container element
        if (sessionizeContainerRef.current) {
          const response = await fetch(
            'https://sessionize.com/api/speaker/events/b15402bf-4607-4ac8-a06c-2a0d3cb3c68b/1x0x3f82b3x',
          );
          const data = await response.text();

          // Create an iframe
          const iframeElement = document.createElement('iframe');
          iframeElement.style.width = '100%';
          iframeElement.style.border = 'none';
          sessionizeContainerRef.current.appendChild(iframeElement);

          iframeElement.onload = () => {
            const iframeDoc =
              iframeElement.contentDocument ??
              iframeElement.contentWindow.document;
            const styleElement = iframeDoc.createElement('style');
            styleElement.innerHTML = `
              body {
                margin: 0;
                display: flex;
                justify-content: center;
                align-items: center;

              }
              /* For WebKit-based browsers (Chrome, Safari, etc.) */
              ::-webkit-scrollbar {
                width: 10px;
                scrollbar-color: transparent;
              }

              ::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                border-radius: 10px;
              }

              /* For Firefox */
              * {
                scrollbar-width: thin;
                scrollbar-color: darkgrey transparent;
              }
            `;
            iframeDoc.head.appendChild(styleElement);
            const scriptElement = iframeDoc.createElement('script');
            scriptElement.innerHTML = data;
            iframeDoc.body.appendChild(scriptElement);
          };
        }
      } catch (error: unknown) {
        console.error('Error fetching data from sessionize.com:', error);
      }
    };

    fetchData().catch(error => {
      console.error('Error fetching data from sessionize.com:', error);
    });
  }, [sessionizeContainerRef]);
}

export default SessionizeFrame;
